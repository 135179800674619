<template>
  <div class="box">
    <div class="content">
      <div class="main_left">
        <div class="banner">
          <div class="banner_left">
            <el-carousel :interval="3000" arrow="always" direction="vertical" height="410px">
              <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                <div class="title">{{ item.journalisTitle }}</div>
                <el-image class="img" :src="echoImg(item.bannerUrl)" @click="goDetails('', item.id)" fit="cover"></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <template v-for="(item, index) in subjectList" @key="index">
          <div class="list_box1" v-if="index == 0 || index == 1" :key="index">
            <div class="navTitle"></div>
            <a :style="`border-top: 2px ${color} solid`">{{ item.navigationName }}</a>
            <ul class="list_children">
              <template v-for="(children, idx) in item.journalism" @key="idx">
                <li :key="idx" :class="idx % 2 != 0 ? 'marginR0' : ''" v-if="idx < 10" @click="goDetails(item.navigationName, children.id)">
                  <span class="text">·{{ children.journalisTitle }}</span>
                  <span class="time">{{ toDate(children.releaseTime, "MM-dd") }}</span>
                </li>
              </template>
            </ul>
          </div>
          <div class="list_box1 list_box2" v-else :class="index % 2 != 0 ? 'marginR0' : ''" :key="index">
            <div class="navTitle"></div>
            <a :style="`border-top: 2px ${color} solid`">{{ item.navigationName }}</a>
            <ul class="list_children">
              <template v-for="(children, idx) in item.journalism" @key="idx" >
                <li v-if="idx < 5" @click="goDetails(item.navigationName, children.id)" :key="idx">
                  <span class="text">·{{ children.journalisTitle }}</span>
                  <span class="time">{{ toDate(children.releaseTime, "MM-dd") }}</span>
                </li>
              </template>
            </ul>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  selectLink,
  selectMapDepot,
  selectBanner,
  getNavigationTwoList,
  selectList,
} from "@/api/template";
export default {
  name: "Index",
  components: {},
  computed: {
    ...mapState(["color"]),
  },
  data() {
    return {
      linksList: [],
      mapDepotList: [],
      bannerList: [],
      subjectList: [],
      liBoxML: 0,
      childrenList: [],
      calleft: 0,
      speed: 1,
      timer: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getSelectBanner();
      this.getSelectList();
    },
    getSelectList() {
      let self = this;
      selectList().then((res) => {
        if (res.code == 200) {
          this.subjectList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => {});
    },
    getSelectLink() {
      let self = this;
      selectLink().then((res) => {
        if (res.code == 200) {
          this.linksList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => {});
    },
    getSelectBanner() {
      let self = this;
      selectBanner().then((res) => {
        if (res.code == 200) {
          this.bannerList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => {});
    },
    getSelectMapDepot() {
      let self = this;
      selectMapDepot().then((res) => {
        if (res.code == 200) {
          this.mapDepotList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => {});
    },
    getNavigationTwoList() {
      let self = this;
      getNavigationTwoList().then((res) => {
        if (res.code == 200) {
          res.data.forEach((dates) => {
            dates.witemVo.forEach((item, index) => {
              if (index == 0) {
                item.isCheck = true;
                this.childrenList = item;
              } else {
                item.isCheck = false;
              }
              item.journalism.forEach((its, idx) => {
                if (idx == 0) {
                  its.isCheck = true;
                } else {
                  its.isCheck = false;
                }
              });
            });
          });
          this.subjectList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => {});
    },
    openLinks(linkUrl) {
      window.open(linkUrl, "_blank");
    },
    more(navigationName, id, type) {
      // let data = {
      //   name: navigationName,
      //   id: id,
      //   type: type,
      // };
      // window.sessionStorage.setItem("navigationValue", JSON.stringify(data));
      // this.$router.push({
      //   params: { name: navigationName, id: id },
      //   name: "fourth-list",
      // });
      this.$router.push({params: {name:navigationName,id:id,type: type},name:'fourth-list'})
    },
    goDetails(navigationName, id) {
      let data = {
        name: navigationName,
        id: id,
      };
      window.sessionStorage.setItem("detailsValue", JSON.stringify(data));
      this.$router.push("/fourth-details");
    },
    changeActive(index, idx) {
      this.subjectList[index].witemVo.forEach((item, num) => {
        if (idx == num) {
          item.isCheck = true;
          this.childrenList = item;
        } else {
          item.isCheck = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 980px;
  margin: 0 auto;
  .main_left {
    float: left;
    display: inline-block;
    width: 980px;
    padding-right: 15px;
    margin-right: 15px;
    //border-right: 1px solid #eee;
    .banner {
      width: 100%;
      height: 410px;
      margin-bottom: 20px;
      .banner_left {
        position: relative;
        float: left;
        width: 980px;
        height: 100%;
        background: #eee;
        .title {
          position: absolute;
          z-index: 99;
          width: 960px;
          height: 32px;
          padding: 0 10px;
          line-height: 32px;
          left: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
        }
        .img {
          width: 100%;
          height: 100%;
        }
      }
      .banner_right {
        float: right;
        width: 135px;
        height: 100%;
        background: #999;
      }
    }
    .list_box1 {
      position: relative;
      margin-bottom: 15px;
      .navTitle {
        height: 40px;
        line-height: 40px;
        background: #f9f9f9;
        border: 1px #d2d2d2 solid;
        margin-bottom: 10px;
      }
      a {
        position: absolute;
        top: 0;
        left: 1px;
        //display: inline-block;
        color: #2f2f2f;
        min-width: 108px;
        padding: 0 20px;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #fff;
        border-top: 2px #0c87ef solid;
        border-right: 1px #d2d2d2 solid;
        font-size: 16px;
        font-family: Microsoft YaHei;
        display: -webkit-box;
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏后添加省略号*/
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; //想显示多少行
      }
      .list_children {
        width: 980px;
        height: 140px;
        li {
          float: left;
          width: 470px;
          height: 26px;
          line-height: 26px;
          margin-right: 30px;
          cursor: pointer;
          .text {
            width: 415px;
            float: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
          .time {
            float: right;
          }
        }
        li:hover {
          color: #0c87ef;
        }
      }
    }
    .list_box2 {
      float: left;
      width: 475px;
      margin-bottom: 15px;
      margin-right: 30px;
      .list_children {
        width: 335px !important;
        li {
          cursor: pointer;
        }
      }
    }
  }
  .main_right {
    float: right;
    display: inline-block;
    width: 264px;
  }
}
.marginR0 {
  margin-right: 0 !important;
}
</style>
